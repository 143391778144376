







































































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

/**
 * input的配置项
 */
@Component({
  name: 'TextConfig',
  components: {}
})
export default class extends Mixins(ElePropertiesMixins) {
  mounted() {
    // console.log(this.widget.properties);
  }
}
